import React, { useState } from "react";
import { Media } from "reactstrap";



const giftData = [
  
];

const GiftList = (props) => {
  return (
    <div className="media">
      <div className="me-3">
        <Media src={props.gift.img1} alt="Generic placeholder image" />
      </div>
      <div className="media-body">
        <h5 className="mt-0">{props.gift.title}</h5>
        <div>
          <Media src={props.gift.img2} className="cash" alt="curancy" />
          {props.gift.desc}
        </div>
      </div>
    </div>
  );
};

const MenuGift = () => {
  const [showState, setShowState] = useState(false);
  return (
    <div className={`d-lg-inline-block d-none btn-group ${showState ? "show" : ""}`}>
      <div className="gift-block" data-toggle="dropdown" onClick={() => setShowState(!showState)}>
        <div className="grif-icon">
          <i className="icon-gift"></i>
        </div>
        <div className="gift-offer">
          <p>gift box</p>
          <span className="d-xl-inline-block d-none">Festivel Offer</span>
        </div>
      </div>
      <div className={`dropdown-menu gift-dropdown ${showState ? "show" : ""}`}>
        {giftData.map((data, i) => (
          <GiftList gift={data} key={i} />
        ))}
      </div>
    </div>
  );
};

export default MenuGift;
